import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { H5 } from 'components/atoms/Typography';
import { ShapeLinkButton } from 'components/atoms/Button/Button';
import { colors, breakPoints } from 'utils/variables';
import emptyImage from 'images/emptyImage.jpg';
import { hexToRGBA } from 'utils/lib';
import ThemeShape9 from 'images/themeShapes/themeShape_9.svg';
import PartModalContent from 'components/molecules/PartModalContent/PartModalContent';
import Modal from 'react-modal';

const StyledWrapper = styled.div`
  position: relative;
  width: 30rem;
  margin-bottom: 6rem;
  margin-left: 1rem;
  margin-right: 1rem;
  text-align: center;

  @media (min-width: ${breakPoints.tablet}) {
    width: 34.5rem;
    margin-left: 0;
    margin-right: 0;
    text-align: left;

    &:nth-child(6n + 5),
    &:nth-child(6n + 6) {
      margin-bottom: 10rem;
    }
  }

  @media (min-width: ${breakPoints.desktop}) {
    width: 38.4rem;

    &:nth-child(3n + 2) {
      margin-left: 2.4rem;
      margin-right: 2.4rem;
    }

    &:nth-child(6n + 4),
    &:nth-child(6n + 5),
    &:nth-child(6n + 6) {
      margin-bottom: 8.7rem;
    }
  }

  &:nth-child(6n + 1):before {
    content: ' ';
    display: block;
    position: absolute;
    background-image: url(${ThemeShape9});
    width: 10.4rem;
    height: 1.95rem;
    top: -2.8rem;
  }

  > h5 {
    margin: 1.7rem 0 0 0;
    min-height: 4rem;

    @media (min-width: ${breakPoints.tablet}) {
      margin: 1.7rem 0 1rem 0;
    }
  }

  > button {
    border: 0;
    cursor: pointer;
    padding: 0;
    margin-top: 2rem;

    @media (min-width: ${breakPoints.tablet}) {
      padding: 0 1.3rem 0 0;
      margin-top: 0.9rem;
    }

    svg {
      margin-left: 2.5rem;
    }
  }
`;

const StyledImageContainer = styled.div`
  position: relative;
  width: inherit;

  .part-image {
    width: inherit;
    height: 20rem;
    cursor: crosshair;

    @media (min-width: ${breakPoints.tablet}) {
      height: 25rem;
    }

    @media (min-width: ${breakPoints.desktop}) {
      height: 29.7rem;
    }

    img {
      @media (min-width: ${breakPoints.desktop}) {
        transition: transform 300ms ease, opacity 500ms ease !important;
        transform: scale(1);

        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }

  > div {
    box-shadow: 0 0.3rem 0.6rem ${hexToRGBA('#000000', 0.16)};
    border-radius: 0.3rem;
  }
`;

const StyledDescription = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 11.5rem;
  overflow-y: hidden;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  > span {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2.3rem;
    color: ${colors.color2};
  }
`;

const getDescription = (description, index) => (
  <span key={`${description.name}-${index}`}>
    <b>{description.name}:</b> {description.value}
  </span>
);

const ShortPartTile = ({ part }) => {
  const { image, title, description } = part;

  const [modalIsOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <StyledWrapper>
      <StyledImageContainer>
        {image.childImageSharp ? (
          <Img fluid={image.childImageSharp.fluid} alt={title} className="part-image" />
        ) : (
          <img src={emptyImage} alt="empty" className="part-image" />
        )}
      </StyledImageContainer>
      <H5>{title}</H5>
      <StyledDescription>{description.map(getDescription)}</StyledDescription>
      <ShapeLinkButton onClick={openModal} text="Dodatkowe informacje" />
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} className="Modal-content" overlayClassName="Modal-overlay">
        <PartModalContent part={part} closeModal={closeModal} />
      </Modal>
    </StyledWrapper>
  );
};

ShortPartTile.propTypes = {
  part: PropTypes.shape({
    image: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object,
      }),
    }),
    title: PropTypes.string,
    description: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default ShortPartTile;
