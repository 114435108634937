import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { H1 } from 'components/atoms/Typography';
import { breakPoints, colors } from 'utils/variables';
import Container from 'components/atoms/Container/Container';
import Menu from 'components/organisms/Menu/Menu';

const StyledWrapper = styled.div`
  margin: 0 0 11.6rem 0;
  opacity: 0;
  animation: ${keyframes`
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    `} 1500ms ease forwards;
`;

const StyledContent = styled.div`
  position: relative;
  margin-top: 4rem;

  @media (min-width: ${breakPoints.tablet}) {
    margin-top: 15rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    margin-top: 28rem;
  }
`;

const CustomHeader = ({ title }) => (
  <Container>
    <Menu />
    <StyledWrapper>
      <StyledContent>
        <H1 color={colors.color1} dangerouslySetInnerHTML={{ __html: title }} />
      </StyledContent>
    </StyledWrapper>
  </Container>
);

CustomHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default CustomHeader;
