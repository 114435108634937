import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PartTile from 'components/molecules/PartTile/PartTile';
import ThemeShape5 from 'images/themeShapes/themeShape_5.svg';
import { breakPoints } from 'utils/variables';

const StyledWrapper = styled.div`
  position: relative;
  margin: 10rem 0 0 0;

  @media (min-width: ${breakPoints.tablet}) {
    margin: 16.4rem 0 -0.7rem 0;
  }

  > button {
    position: relative;
    display: block;
    z-index: 10;
    margin: 6.2rem auto 0 auto;
  }
`;

const StyledContent = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  @media (min-width: ${breakPoints.tablet}) {
    justify-content: space-between;
  }

  @media (min-width: ${breakPoints.desktop}) {
    justify-content: flex-start;
  }
`;

const StyledShape = styled.img`
  position: absolute;
  top: 0;
  right: -80%;
  height: 100%;

  @media (min-width: ${breakPoints.tablet}) {
    top: 23rem;
    right: unset;
    left: -51.5%;
    height: auto;
  }
`;

const getTiles = (part, index) => <PartTile part={part.frontmatter} key={`${part.frontmatter.title}-${index}`} />;

const Shop = ({ parts }) => (
  <StyledWrapper>
    <StyledContent>{parts.map(getTiles)}</StyledContent>
    <StyledShape src={ThemeShape5} alt="Shop shape" />
  </StyledWrapper>
);

Shop.propTypes = {
  parts: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        childImageSharp: PropTypes.shape({
          fluid: PropTypes.object,
        }),
      }),
      title: PropTypes.string,
      description: PropTypes.arrayOf({
        name: PropTypes.string,
        value: PropTypes.string,
      }),
    }),
  ).isRequired,
};

export default Shop;
