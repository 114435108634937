import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ThemeShape4 from 'images/themeShapes/themeShape_4.inline.svg';
import { colors, breakPoints } from 'utils/variables';

const StyledPaginationInfo = styled.div`
  position: relative;
  color: ${colors.color1};
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.8rem;
  letter-spacing: 0.18rem;
  min-width: 20rem;
  text-align: center;

  > svg {
    stroke: ${colors.color1};
    display: none;

    @media (min-width: ${breakPoints.tablet}) {
      display: unset;
    }

    &:first-child {
      margin-right: 1.2rem;
    }

    &:last-child {
      margin-left: 1.2rem;
    }
  }
`;

const PaginationInfo = ({ currentPage, pageCount }) => (
  <StyledPaginationInfo>
    <ThemeShape4 />
    {`Strona ${currentPage} z ${pageCount}`}
    <ThemeShape4 />
  </StyledPaginationInfo>
);

PaginationInfo.propTypes = {
  currentPage: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
};

export default PaginationInfo;
