import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ThemeShape4 from 'images/themeShapes/themeShape_4.inline.svg';
import { colors, breakPoints } from 'utils/variables';
import { Link } from 'gatsby';

const StyledPaginationButton = styled(Link)`
  position: relative;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  color: ${colors.color3};
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.8rem;
  transition: opacity 300ms ease;
  opacity: ${({ show }) => (show === 'true' ? '1' : '0')};
  pointer-events: ${({ show }) => (show === 'true' ? 'auto' : 'none')};
  letter-spacing: 0.18rem;
  max-width: 17.6rem;
  display: flex;
  align-items: center;
  padding: 0;
  text-align: center;
  text-decoration: none;
  transform: ${({ prev }) => (prev === 'true' ? 'rotate(-90deg)' : 'rotate(90deg)')};
  height: 5rem;

  @media (min-width: ${breakPoints.tablet}) {
    transform: unset;
    height: auto;
  }

  > span {
    display: none;

    @media (min-width: ${breakPoints.tablet}) {
      display: block;
    }
  }

  > svg {
    min-width: 3.75rem;
    stroke: ${colors.color3};

    &:first-child {
      display: none;

      @media (min-width: ${breakPoints.tablet}) {
        margin-right: 1.5rem;
        display: block;
      }
    }

    &:last-child {
      @media (min-width: ${breakPoints.tablet}) {
        margin-left: 1.5rem;
      }
    }
  }
`;

const getDirectionText = prev => (prev ? 'Poprzednia strona' : 'Następna strona');

const PaginationButton = ({ prev, to, show }) => (
  <StyledPaginationButton prev={prev.toString()} show={show.toString()} title={getDirectionText(prev)} to={to}>
    <ThemeShape4 />
    <span>{getDirectionText(prev)}</span>
    <ThemeShape4 />
  </StyledPaginationButton>
);

PaginationButton.propTypes = {
  prev: PropTypes.bool,
  to: PropTypes.string,
  show: PropTypes.bool,
};

PaginationButton.defaultProps = {
  prev: false,
  to: '',
  show: false,
};

export default PaginationButton;
