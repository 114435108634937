import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'templates/Layout/Layout';
import Container from 'components/atoms/Container/Container';
import Footer from 'components/organisms/Footer/Footer';
import CustomHeader from 'components/organisms/CustomHeader/CustomHeader';
import Shop from 'components/organisms/Shop/Shop';
import Pagination from 'components/molecules/Pagination/Pagination';
import { graphql } from 'gatsby';

const Page = ({ data, pageContext }) => (
  <Layout
    metaTitle="Sprzedaży części samochodowych Rybnik - Auto Serwis Kubeczek"
    metaDescription="Prowadzimy sprzedaż części samochodowych sprawdź naszą ofertę. Kubeczek auto serwis Rybnik sprzedaż części samochodowych oraz ich montaż."
  >
    <CustomHeader title={'Sprzedaż <b>części</b>'} />
    <Container>
      <Shop parts={data.allMarkdownRemark.nodes} />
      <Pagination pageInfo={data.allMarkdownRemark.pageInfo} pageCount={pageContext.pageCount} />
    </Container>
    <Footer />
  </Layout>
);

Page.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      nodes: PropTypes.array,
      pageInfo: PropTypes.shape(),
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    pageCount: PropTypes.number,
  }).isRequired,
};

export default Page;

export const pageQuery = () => graphql`
  query query($skip: Int!, $limit: Int!) {
    allMarkdownRemark(sort: { order: DESC, fields: frontmatter___title }, limit: $limit, skip: $skip) {
      pageInfo {
        currentPage
        hasNextPage
        pageCount
        perPage
        itemCount
        hasPreviousPage
      }
      nodes {
        id
        frontmatter {
          image {
            childImageSharp {
              fluid(quality: 100, maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          title
          description {
            name
            value
          }
        }
      }
    }
  }
`;
