import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PaginationButton from 'components/atoms/PaginationButton/PaginationButton';
import PaginationInfo from 'components/atoms/PaginationInfo/PaginationInfo';
import { breakPoints } from 'utils/variables';

const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4rem;

  @media (min-width: ${breakPoints.tablet}) {
    margin-bottom: 8rem;
  }
`;

const Pagination = ({ pageInfo, pageCount }) => {
  const prevLink = `/sprzedaz-czesci${pageInfo.currentPage !== 2 ? `/${pageInfo.currentPage - 1}` : ''}`;
  const nextLink = `/sprzedaz-czesci/${pageInfo.currentPage + 1}`;

  return (
    <StyledWrapper>
      <PaginationButton prev show={pageInfo.hasPreviousPage} to={prevLink} />
      <PaginationInfo currentPage={pageInfo.currentPage} pageCount={pageCount} />
      <PaginationButton show={pageInfo.hasNextPage} to={nextLink} />
    </StyledWrapper>
  );
};

Pagination.propTypes = {
  pageInfo: PropTypes.shape({
    currentPage: PropTypes.number,
    hasNextPage: PropTypes.bool,
    hasPreviousPage: PropTypes.bool,
  }).isRequired,
  pageCount: PropTypes.number.isRequired,
};

export default Pagination;
